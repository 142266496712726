import PrintTemplate, {
  FooterType,
} from "src/components/Printing/PrintTemplate";
import { useEffect, useMemo, useState } from "react";
import { IAddress } from "src/interfaces";
import { getDisplayPhoneNumber } from "src/helpers/phoneNumber.helper";
import PrintPreviewActionButton from "src/components/Printing/PrintPreviewActionButton";
import {
  Stack,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  Table,
  TableContainer,
  TableBody,
  Divider,
} from "@mui/material";
import { useClubs } from "src/features/club/ClubProvider";
import { IUseClub } from "src/interfaces";
import SoftTypography from "src/components/SoftTypography";
import { format } from "date-fns";
import { formatCurrency } from "src/features/utils";
import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { getClubTransactionsCollection } from "src/features/transaction/collection";
import { doc, Timestamp } from "firebase/firestore";
import SoftBox from "src/components/SoftBox";

const getDate = (date: any): string | null => {
  if (date instanceof Date) return format(date, "MM/dd/yyyy");
  if (date instanceof Timestamp) return format(date.toDate(), "MM/dd/yyyy");
  if (typeof date === "string") return format(new Date(date), "MM/dd/yyyy");
  return null;
};

const PageTitleText = ({ text }: { text: string }) => {
  return (
    <SoftTypography
      color="black"
      variant="caption"
      fontSize="20px"
      fontWeight="bold"
    >
      {text}
    </SoftTypography>
  );
};

const BoldText = ({ text }: { text: string }) => {
  return (
    <SoftTypography
      color="black"
      variant="caption"
      fontSize="14px"
      fontWeight="bold"
    >
      {text}
    </SoftTypography>
  );
};

const GreyText = ({ text }: { text: string }) => {
  return (
    <SoftTypography fontWeight="medium" color="#91A0B2" variant="caption">
      {text}
    </SoftTypography>
  );
};

const NormalText = ({ text }: { text: string }) => {
  return (
    <SoftTypography c="black" variant="caption">
      {text}
    </SoftTypography>
  );
};

const TableBorderColor = "#D0D0D0";

const InvoiceAddress = ({
  title,
  address,
}: {
  title: string;
  address: IAddress;
}) => {
  return (
    <Stack gap="0.5rem" pt={1}>
      <GreyText text={title} />
      <Address address={address} />
    </Stack>
  );
};

const Address = ({
  address,
  phoneNumber,
}: {
  address: IAddress;
  phoneNumber?: string;
}) => {
  if (!address) {
    return null;
  }

  const displayPhoneNumber = phoneNumber
    ? getDisplayPhoneNumber(phoneNumber)
    : "";

  return (
    <Stack gap="0.1rem">
      <NormalText text={address.addressLine1} />
      {address.addressLine2 && <NormalText text={address.addressLine2} />}
      <NormalText
        text={address.city + ", " + address.state + ", " + address.zip}
      />
      <NormalText text={address.country} />
      {displayPhoneNumber && <NormalText text={displayPhoneNumber} />}
    </Stack>
  );
};

const CompanyDetails = () => {
  const { selectedClub, selectedLocation }: IUseClub = useClubs();

  return (
    <Stack gap="0.25rem">
      <BoldText text={selectedClub.name} />
      <Address
        address={selectedLocation?.address}
        phoneNumber={selectedLocation?.number}
      />
    </Stack>
  );
};

const TableHeader = ({ text, width }: { text: string; width: string }) => {
  return (
    <MuiTableCell
      style={{
        borderColor: TableBorderColor,
        width,
      }}
    >
      <BoldText text={text} />
    </MuiTableCell>
  );
};

const TableCell = ({ text }: { text: string }) => {
  return (
    <MuiTableCell
      style={{
        borderColor: TableBorderColor,
      }}
    >
      <NormalText text={text} />
    </MuiTableCell>
  );
};

const CurrencyTableCell = ({ amount }: { amount: number }) => {
  const value = formatCurrency(amount);

  return (
    <MuiTableCell
      style={{
        borderColor: TableBorderColor,
      }}
    >
      <NormalText text={value} />
    </MuiTableCell>
  );
};

const InvoiceTable = ({ data }: { data: ITableData[] }) => {
  const rows = data?.map((data) => {
    return (
      <MuiTableRow key={data?.description}>
        <TableCell text={data?.description} />
        <TableCell text={data?.quantity?.toString()} />
        <CurrencyTableCell amount={data?.cost} />
        <CurrencyTableCell amount={data?.total} />
      </MuiTableRow>
    );
  });

  return (
    <TableContainer
      sx={{ borderRadius: 0, borderColor: TableBorderColor, boxShadow: "none" }}
    >
      <Table sx={{ borderRadius: 0, width: "100%" }}>
        <MuiTableHead>
          <MuiTableRow>
            <TableHeader text={"Item Name"} width="35%" />
            <TableHeader text={"Qty"} width="10%" />
            <TableHeader text={"Rate"} width="20%" />
            <TableHeader text={"Total"} width="10%" />
          </MuiTableRow>
        </MuiTableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

const InvoiceDetails = ({ invoice }: { invoice: any }) => {
  console.log(invoice);
  return (
    <Stack gap="0.5rem">
      <Stack direction="row" justifyContent="space-between" width="12rem">
        <GreyText text={"Invoice"} />
        <NormalText text={invoice?.transactionNumber || invoice?.id} />
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="12rem">
        <GreyText text={"Date"} />
        <NormalText text={getDate(invoice?.createdAt) || "N/A"} />
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="12rem">
        <GreyText text={"Terms"} />
        <NormalText text={"Due On Receipt"} />
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="12rem">
        <GreyText text={"Due Date"} />
        <NormalText text={getDate(invoice?.dueDate) || "N/A"} />
      </Stack>
    </Stack>
  );
};

interface ITableData {
  description: string;
  cost: number;
  quantity: number;
  total: number;
}

const LineItemsPerPage = 5;

const calculateTotalPages = (lineItems: any[]) => {
  return Math.ceil((lineItems?.length || 1) / LineItemsPerPage);
};

interface IPageProps {
  invoice: any;
  customer: any;
  pageNumber: number;
  totalPages: number;
  tableData: ITableData[];
}

const InvoicePage = ({
  invoice,
  customer,
  pageNumber,
  totalPages,
  tableData,
}: IPageProps) => {
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === totalPages;

  const subTotal = tableData?.reduce(
    (acc: number, line: any) => acc + (line?.total || 0),
    0
  );

  return (
    <PrintTemplate footerType={FooterType.WHITE}>
      <Stack direction="row" justifyContent="flex-end">
        <NormalText text={`${"Page"} ${pageNumber} ${"of"} ${totalPages}`} />
      </Stack>
      {isFirstPage && (
        <Stack gap="1rem">
          <CompanyDetails />
          <Divider
            sx={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            color="#DCDCDC"
          />
          <Stack gap="0.5rem">
            <Stack gap="0.25rem">
              <BoldText text={customer?.displayName} />
              {customer?.email && <NormalText text={customer?.email} />}
              {customer?.phoneNumber && (
                <NormalText text={customer?.phoneNumber} />
              )}
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              {customer?.address ? (
                <InvoiceAddress title={"Bill To"} address={customer?.address} />
              ) : (
                <SoftBox />
              )}
              {invoice?.shippingAddress ? (
                <InvoiceAddress
                  title={"Ship To"}
                  address={invoice?.shippingAddress}
                />
              ) : (
                <SoftBox />
              )}
              <InvoiceDetails invoice={invoice} />
            </Stack>
          </Stack>
        </Stack>
      )}
      <Divider sx={{ marginTop: "2rem" }} color="#DCDCDC" />
      <InvoiceTable data={tableData} />
      {isLastPage && (
        <SoftBox
          display="flex"
          flexDirection="column"
          gap="0.5rem"
          alignContent="flex-end"
          alignItems="end"
          mt="2rem"
        >
          <Stack direction="row" justifyContent="space-between" width="12rem">
            <NormalText text={"Sub Total"} />
            <BoldText text={formatCurrency(subTotal)} />
          </Stack>
          <Stack direction="row" justifyContent="space-between" width="12rem">
            <NormalText text={"Discount"} />
            <BoldText
              text={formatCurrency(invoice?.discountAmount ?? 0 * -1)}
            />
          </Stack>
          {!invoice?.clubPaysFees && (
            <Stack direction="row" justifyContent="space-between" width="12rem">
              <NormalText text={"Taxes/Fees"} />
              <BoldText
                text={formatCurrency(invoice?.fees?.applicationFee ?? 0)}
              />
            </Stack>
          )}
          <Stack direction="row" justifyContent="space-between" width="12rem">
            <PageTitleText text={"Total"} />
            <PageTitleText
              text={formatCurrency(invoice?.fees?.costToPilot ?? 0)}
            />
          </Stack>
          {invoice?.payments && invoice?.payments?.length > 0 && (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="12rem"
              >
                <NormalText text={"Paid"} />
                <BoldText
                  text={formatCurrency(
                    invoice?.payments?.reduce(
                      (acc: number, i: any) => acc + (i?.amount || 0) / 100,
                      0
                    ) * -1 ?? 0
                  )}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="12rem"
              >
                <GreyText text={"Remaining"} />
                <BoldText
                  text={formatCurrency(
                    invoice?.totalCost -
                      invoice?.payments?.reduce(
                        (acc: number, i: any) => acc + (i?.amount || 0) / 100,
                        0
                      ) ?? 0
                  )}
                />
              </Stack>
            </>
          )}
        </SoftBox>
      )}
    </PrintTemplate>
  );
};

const PrintInvoice = ({
  invoiceId,
  showPageControls,
}: {
  invoiceId: string;
  showPageControls: boolean;
}) => {
  const { selectedClubId }: IUseClub = useClubs();
  const [currentPage, _setCurrentPage] = useState(1);
  const [invoice, setInvoice] = useState<any | null>(null);
  const [totalPages, setTotalPages] = useState(1);
  const [tableData, setTableData] = useState<[]>([]);

  const { data: invoiceData }: { data: any } = useRealtimeDocumentData(
    doc(getClubTransactionsCollection(selectedClubId), invoiceId)
  );

  const customer = useMemo(() => {
    if (!invoiceData) {
      return null;
    }
    return invoiceData?.pilot || invoiceData?.member;
  }, [invoice]);

  // if (!invoice || !customer) {
  //   throw new Error("Invoice not found");
  // }

  useEffect(() => {
    if (!invoiceData) return;

    if (!invoiceData.lineItems) {
      invoiceData.lineItems = [];

      if (invoiceData?.aircraft) {
        if (invoiceData?.aircraft?.billFrom === "tach") {
          invoiceData.lineItems.push({
            description: `Aircraft Rental - N${invoiceData?.aircraft?.tailNumber}`,
            quantity: invoiceData?.bookingData?.totalTach1Hours,
            total:
              invoiceData?.bookingData?.totalTach1Hours *
              invoiceData?.selectedRate?.hourlyRate,
          });
        } else {
          invoiceData.lineItems.push({
            description: `${invoiceData?.aircraft?.tailNumber}`,
            quantity: invoiceData?.bookingData?.totalHobbsHours,
            total:
              invoiceData?.bookingData?.totalHobbsHours *
              invoiceData.selectedRate?.hourlyRate,
          });
        }
        if (invoiceData?.fuelCost > 0) {
          invoiceData.lineItems.push({
            description: `Fuel Reimbursement`,
            quantity: 1,
            total: invoiceData?.fuelCost * -1,
          });
        }
      }

      if (invoiceData?.instuctor) {
        if (invoiceData?.bookingData?.instructionHours > 0) {
          invoiceData.lineItems.push({
            description: `Flight Instruction - ${invoiceData?.instructor?.displayName}`,
            quantity: invoiceData?.bookingData?.instructionHours,
            total: invoiceData?.instructorCost,
          });
        }
        if (invoiceData?.bookingData?.instructionHoursGround > 0) {
          invoiceData.lineItems.push({
            description: `Ground Instruction - ${invoiceData?.instructor?.displayName}`,
            quantity: invoiceData?.bookingData?.instructionHoursGround,
            total: invoiceData?.instructorCostGround,
          });
        }
      }
    }

    setInvoice(invoiceData);
    setTotalPages(calculateTotalPages(invoiceData.lineItems));
  }, [invoiceData]);

  // Handler functions for page navigation
  // const goToNextPage = () => {
  //   if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  // };

  // const goToPreviousPage = () => {
  //   if (currentPage > 1) setCurrentPage(currentPage - 1);
  // };

  useEffect(() => {
    if (!invoice) return;

    const tableData = invoice?.lineItems?.map((line: any): ITableData => {
      let cost = line.total / (line?.quantity || line?.qty || 1);
      let total = line.total;

      if (line?.type && line?.type === "credit") {
        cost *= -1;
        total *= -1;
      }

      return {
        description: line?.description ?? line?.item,
        cost,
        quantity: line?.quantity || line?.qty || 1,
        total,
      };
    });

    setTableData(tableData);
  }, [invoice]);

  return (
    <>
      {showPageControls ? (
        <Stack gap="0" alignContent="flex-start">
          <Stack>
            {totalPages > 1 && (
              <>
                {/* <ActionIcon
                  variant="transparent"
                  color="#91A0B2"
                  size="2rem"
                  onClick={goToPreviousPage}
                  disabled={currentPage === 1}
                >
                  <IconArrowNarrowUp size="2rem" />
                </ActionIcon>
                <ActionIcon
                  variant="transparent"
                  color="#91A0B2"
                  size="2rem"
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages}
                >
                  <IconArrowNarrowDown size="2rem" />
                </ActionIcon> */}
              </>
            )}
            <PrintPreviewActionButton>
              <PrintInvoice invoiceId={invoiceId} showPageControls={false} />
            </PrintPreviewActionButton>
          </Stack>
          <InvoicePage
            invoice={invoice}
            customer={customer}
            pageNumber={currentPage}
            totalPages={totalPages}
            tableData={tableData.slice(
              (currentPage - 1) * LineItemsPerPage,
              currentPage * LineItemsPerPage
            )}
          />
        </Stack>
      ) : (
        Array.from({ length: totalPages }).map((_, index) => (
          <InvoicePage
            key={index}
            invoice={invoice}
            customer={customer}
            pageNumber={index + 1}
            totalPages={totalPages}
            tableData={tableData?.slice(
              index * LineItemsPerPage,
              (index + 1) * LineItemsPerPage
            )}
          />
        ))
      )}
    </>
  );
};

export default PrintInvoice;
