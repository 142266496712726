import { lazy } from "react";
import { ModalType } from "./modalConfiguration";

const AddSquakModal = lazy(
  () => import("./AddSquawkModal/WrappedAddSquawkModal")
);
const SquawkInfo = lazy(() => import("./SquawksModal/WrappedSquawksModal"));
const UploadDocument = lazy(
  () => import("./UploadDocument/WrappedUploadDocumentModal")
);
const NewUploadDocument = lazy(
  () => import("./NewUploadDocument/WrappedUploadDocument")
);
const DocumentViewer = lazy(() => import("./DocumentViewer/DocumentViewer"));
const RoleManager = lazy(() => import("./RoleManager/RoleManager"));
const CheckInModal = lazy(() => import("./CheckInModal/WrappedCheckInModal"));
const BookingInfoModal = lazy(
  () => import("./BookingInfoModal/WrappedBookingInfoModal")
);
const IntroFlightModal = lazy(
  () => import("./IntroFlightModal/WrappedIntroFlightModal")
);
const TransferBookingModal = lazy(
  () => import("./TransferBookingModal/TransferBookingModal")
);
const ConfirmModal = lazy(() => import("./ConfirmModal/ConfirmModal"));

const configBuilder = (component: any, key: ModalType) => ({ component, key });

export const CONFIG = [
  configBuilder(AddSquakModal, ModalType.ADD_SQUAWK),
  configBuilder(SquawkInfo, ModalType.VIEW_SQUAWK),
  configBuilder(UploadDocument, ModalType.UPLOAD_DOCUMENT),
  configBuilder(NewUploadDocument, ModalType.NEW_UPLOAD_DOCUMENT),
  configBuilder(DocumentViewer, ModalType.DOCUMENT_VIEWER),
  configBuilder(RoleManager, ModalType.ROLE_MANAGER),
  configBuilder(CheckInModal, ModalType.CHECKIN_MODAL),
  configBuilder(BookingInfoModal, ModalType.BOOKING_INFO_MODAL),
  configBuilder(IntroFlightModal, ModalType.INTRO_FLIGHT_MODAL),
  configBuilder(TransferBookingModal, ModalType.TRANSFER_BOOKING_MODAL),
  configBuilder(ConfirmModal, ModalType.CONFIRM_BOOKING_MODAL),
];
