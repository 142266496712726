import {
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import {
  getClubBookingsCollection,
  getClubInstructorPreferencesCollection,
} from "src/features/club/collections";
import {
  getAircraftCollection,
  getAircraftSquawksCollection,
  getSimulatorsCollection,
} from "src/features/aircraft/collections";
import { format, set } from "date-fns";

const getInstructorWorkingHours = async (clubId, locationId, instructorId) => {
  const instructorWorkingHoursRef = doc(
    getClubInstructorPreferencesCollection(clubId, locationId),
    instructorId
  );

  const workingHours = await getDoc(instructorWorkingHoursRef);

  return workingHours.data();
};

const sortBookings = (bookings) => {
  const sortedBookings = {
    pending: [],
    cancelled: [],
    completed: [],
    dispatched: [],
    maintenance: [],
    unavailable: [],
    requestOnly: [],
    upcoming: [],
  };

  if (bookings) {
    bookings.map((bookingDoc) => {
      const bookingData = bookingDoc.data();
      if (bookingData.cancelled) {
        sortBookings.cancelled.push(bookingData);
      } else if (bookingData.extendedProps.type.value === "requestOnly") {
        sortedBookings.requestOnly.push(bookingData);
      } else if (bookingData.extendedProps.type.value === "maintenance") {
        sortedBookings.maintenance.push(bookingData);
      } else if (bookingData.extendedProps.type.value === "unavailable") {
        sortedBookings.unavailable.push(bookingData);
      } else if (bookingData.completed) {
        sortedBookings.completed.push(bookingData);
      } else if (bookingData.dispatched) {
        sortedBookings.dispatched.push(bookingData);
      } else if (!bookingData.confirmed) {
        sortedBookings.pending.push(bookingData);
      } else {
        sortedBookings.upcoming.push(bookingData);
      }
    });
  }
  return sortedBookings;
};

const getOverlappingBookings = async (booking) => {
  const overlappingBookingsDocs = await getDocs(
    query(
      getClubBookingsCollection(
        booking.extendedProps.clubId,
        booking.extendedProps.locationId
      ),
      where("end", ">", booking.start),
      where("resourceIds", "array-contains-any", booking.resourceIds)
    )
  );

  const overlappingBookings = {
    pending: [],
    cancelled: [],
    completed: [], //
    dispatched: [], //
    maintenance: [],
    unavailable: [], //
    requestOnly: [],
    upcoming: [], //
  };

  if (overlappingBookingsDocs) {
    overlappingBookingsDocs.docs.map((bookingDoc) => {
      if (bookingDoc.id === booking.id) return;
      const overlappingBooking = bookingDoc.data();
      if (overlappingBooking.start.toDate() < booking.end) {
        if (overlappingBooking.cancelled) {
          overlappingBookings.cancelled.push(overlappingBooking);
        } else if (
          overlappingBooking.extendedProps.type.value === "requestOnly"
        ) {
          overlappingBookings.requestOnly.push(overlappingBooking);
        } else if (
          overlappingBooking.extendedProps.type.value === "maintenance"
        ) {
          overlappingBookings.maintenance.push(overlappingBooking);
        } else if (
          overlappingBooking.extendedProps.type.value === "unavailable"
        ) {
          overlappingBookings.unavailable.push(overlappingBooking);
        } else if (overlappingBooking.completed) {
          overlappingBookings.completed.push(overlappingBooking);
        } else if (overlappingBooking.dispatched) {
          overlappingBookings.dispatched.push(overlappingBooking);
        } else if (!overlappingBooking.confirmed) {
          overlappingBookings.pending.push(overlappingBooking);
        } else {
          overlappingBookings.upcoming.push(overlappingBooking);
        }
      }
    });
  }
  return overlappingBookings;
};

const fetchAircraftResourceData = async (clubId, locationId, selectedClub) => {
  const aircraftsSnapshot = await getDocs(
    query(
      getAircraftCollection(clubId),
      where("deleted", "==", false),
      where("disabled", "==", false),
      where("locations", "array-contains", locationId),
      orderBy("index", "asc")
    )
  );

  const aircraftResources = [];
  if (aircraftsSnapshot) {
    await Promise.all(
      aircraftsSnapshot.docs.map(async (document) => {
        const aircraft = document.data();

        const aircraftsSquawksSnapshot = await getDocs(
          query(
            getAircraftSquawksCollection(clubId, document.id),
            where("grounded", "==", true),
            where("resolved", "==", false)
          )
        );
        if (aircraftsSquawksSnapshot.docs.length > 0) {
          aircraft.groundingSquawks = aircraftsSquawksSnapshot.docs.map(
            (data) => data.data()
          );
        }

        aircraftResources.push({
          id: document.id,
          title: selectedClub?.preferences?.calendar?.hideTailNumbers
            ? `${aircraft.displayName}`
            : `N${aircraft.tailNumber} - ${aircraft.displayName}`,
          type: "Aircraft",
          showTailNumber: !selectedClub?.preferences?.calendar?.hideTailNumbers,
          order: 1,
          payload: aircraft,
        });
      })
    );
  }
  return aircraftResources;
};

const fetchSimulatorResourceData = async (clubId, locationId) => {
  const simulatorsSnapshot = await getDocs(
    query(
      getSimulatorsCollection(clubId),
      where("deleted", "==", false),
      where("disabled", "==", false),
      where("locations", "array-contains", locationId),
      orderBy("index", "asc")
    )
  );

  const simulatorResources = [];
  if (simulatorsSnapshot) {
    await Promise.all(
      simulatorsSnapshot.docs.map(async (document) => {
        const simulator = document.data();
        simulatorResources.push({
          id: document.id,
          title: `${simulator.displayName}`,
          type: "Simulators",
          order: 2,
          payload: simulator,
        });
      })
    );
  }
  return simulatorResources;
};

const fetchInstructorResourceData = (instructors, instructorPrefs) => {
  const instructorResources = [];

  instructors
    .map((instructor) => ({
      ...instructor,
      ...(instructorPrefs.get(instructor?.id) ?? {}),
      index: instructorPrefs.get(instructor?.id)?.index ?? 0,
    }))
    .filter((i) => !i?.hidden)
    .map((instructor) => {
      const instructorResource = {
        id: instructor?.id,
        title: instructor?.displayName,
        type: "Instructors",
        order: instructor?.index,
        payload: instructor,
      };

      if (instructor?.workingHours) {
        const workingHours = [];
        const days = [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ];
        days.forEach((d) => {
          const workingHoursData = instructor.workingHours[d];
          const startDate = workingHoursData.start.toDate();
          const endDate = workingHoursData.end.toDate();
          if (!workingHoursData.isOff && !workingHoursData.isRequestOnly) {
            workingHours.push({
              daysOfWeek: [days.indexOf(d)],
              startTime: `${format(startDate, "HH:mm")}`,
              endTime: `${format(endDate, "HH:mm")}`,
            });
          }
        });
        instructorResource.businessHours = workingHours;
      }

      instructorResources.push(instructorResource);
    });

  return instructorResources.sort(
    (a, b) => a.payload?.index - b.payload?.index
  );
};

const fetchClubPilotsData = (clubUsers, clubUserPermissions) => {
  const pilots = [];

  clubUsers.map((user) => {
    const permission = clubUserPermissions.get(user.id);
    user.userRoles = permission?.userRoles;
    user.deleted = permission?.deleted;

    if (!user.deleted) pilots.push(user);
  });

  return pilots;
};

const isWithinInstructorWorkingHours = async (booking, customInstructorId) => {
  const instructorId =
    customInstructorId ||
    booking.extendedProps?.instructor?.value ||
    booking.instructor?.value;

  if (!instructorId) return true;

  const instructorsWorkingHours = await getInstructorWorkingHours(
    booking.extendedProps?.clubId || booking.clubId,
    booking.extendedProps?.locationId || booking.locationId,
    instructorId
  );

  if (!instructorsWorkingHours?.workingHours) return true;

  const bookingDayOfWeek =
    booking.start.getDay?.() ?? booking.start.toDate().getDay();
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  if (instructorsWorkingHours.workingHours[days[bookingDayOfWeek]].isOff)
    return false;
  if (
    instructorsWorkingHours.workingHours[days[bookingDayOfWeek]].isRequestOnly
  )
    return false;

  const instructorWorkignHoursDateStart =
    instructorsWorkingHours.workingHours[days[bookingDayOfWeek]].start.toDate();
  const instructorWorkignHoursDateEnd =
    instructorsWorkingHours.workingHours[days[bookingDayOfWeek]].end.toDate();
  const workingHoursStart = set(booking.start?.toDate?.() || booking.start, {
    hours: instructorWorkignHoursDateStart.getHours(),
    minutes: instructorWorkignHoursDateStart.getMinutes(),
  });
  const workingHoursEnd = set(booking.start?.toDate?.() || booking.start, {
    hours: instructorWorkignHoursDateEnd.getHours(),
    minutes: instructorWorkignHoursDateEnd.getMinutes(),
  });

  return (
    (booking.start?.toDate?.() || booking.start) >= workingHoursStart &&
    (booking.end?.toDate?.() || booking.end) <= workingHoursEnd
  );
};

const isOverlappingAnotherBooking = async (booking, clubPreferences) => {
  const overlappingBookings = await getOverlappingBookings(booking);

  if (clubPreferences?.calendar?.allowOverlappingBookings) {
    if (
      overlappingBookings.maintenance.length > 0 ||
      overlappingBookings.unavailable.length > 0
    ) {
      return true;
    }
    return false;
  }
  if (
    overlappingBookings.upcoming.length > 0 ||
    overlappingBookings.maintenance.length > 0 ||
    overlappingBookings.unavailable.length > 0 ||
    overlappingBookings.dispatched.length > 0 ||
    overlappingBookings.completed.length > 0
  ) {
    return true;
  }
  return false;
};

const getListOfAvailableInstructors = async (startDate, endDate) => {
  console.log("getListOfAvailableInstructors", startDate, endDate);
  return [];
};

const getListOfAvailableAircrafts = async (startDate, endDate) => {
  console.log("getListOfAvailableAircrafts", startDate, endDate);
  return [];
};

export {
  sortBookings,
  fetchAircraftResourceData,
  fetchSimulatorResourceData,
  fetchClubPilotsData,
  fetchInstructorResourceData,
  isWithinInstructorWorkingHours,
  isOverlappingAnotherBooking,
  getOverlappingBookings,
  getListOfAvailableInstructors,
  getListOfAvailableAircrafts,
};
