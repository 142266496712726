// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import InfoIcon from "@mui/icons-material/Info";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import TopicIcon from "@mui/icons-material/Topic";
import BuildIcon from "@mui/icons-material/Build";

function Sidenav() {
  const sidenavItems = [
    {
      icon: <InfoIcon />,
      label: "club info",
      href: "club-info",
    },
    {
      icon: <EditLocationIcon />,
      label: "locations",
      href: "locations",
    },
    {
      icon: <CardMembershipIcon />,
      label: "membership",
      href: "membership",
    },
    {
      icon: <EventBusyIcon />,
      label: "cancellation reasons",
      href: "cancellation-reasons",
    },
    {
      icon: <BuildIcon />,
      label: "squawk statuses",
      href: "squawk-statuses",
    },
    {
      icon: <EventAvailableIcon />,
      label: "time off approval",
      href: "time-off-approval",
    },
    {
      icon: <CalendarMonthIcon />,
      label: "scheduling",
      href: "scheduling",
    },
    {
      icon: <TopicIcon />,
      label: "documents",
      href: "documents",
    },
    {
      icon: <NotificationsActiveIcon />,
      label: "notifications",
      href: "notifications",
    },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <SoftBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <SoftTypography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <SoftBox mr={1.5} lineHeight={1}>
            {icon}
          </SoftBox>
          {label}
        </SoftTypography>
      </SoftBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "135px",
      }}
    >
      <SoftBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{
          listStyle: "none",
        }}
      >
        {renderSidenavItems}
      </SoftBox>
    </Card>
  );
}

export default Sidenav;
