import { useState } from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "src/features/utils";

// @mui material components
import {
  Grid,
  Modal,
  Divider,
  TableBody,
  TableRow,
  Table,
} from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftAvatar from "src/components/SoftAvatar";
import SoftBadge from "src/components/SoftBadge";

import ReservationDate from "src/components/ReservationDate";
import BookingData from "src/modals/CheckOutModal/components/BookingData";
import { BookingInfoDynamicProps } from "./bookingInfoDynamicProps";
import { AircraftInfoModal } from "src/modals/AircraftInfoModal";
import { UserInfoModal } from "src/modals/UserInfoModal";
import BookingInfoModal from "src/modals/BookingInfoModal";

import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";
import SoftButton from "src/components/SoftButton";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import ModalContainer from "src/components/ModalContainer";

// Images
const orderImage =
  "https://images.unsplash.com/photo-1511499767150-a48a237f0083?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80";

function DynamicPropCard({ dynamicProp, bookingData, onClick }) {
  return (
    <SoftBox>
      <SoftBox display="flex" alignItems="center">
        <SoftBox
          display="grid"
          alignItems="center"
          justifyContent="center"
          borderRadius="lg"
          width="2rem"
          height="2rem"
          mr={2}
          sx={{ cursor: onClick ? "pointer" : "default" }}
          onClick={onClick}
        >
          <dynamicProp.icon
            sx={{
              display: "grid",
              placeItems: "center",
            }}
            color="dark"
            fontSize="medium"
          />
        </SoftBox>
        <SoftBox
          display="flex"
          flexDirection="column"
          sx={{ cursor: onClick ? "pointer" : "default" }}
          onClick={onClick}
        >
          <SoftTypography
            variant="button"
            color="dark"
            fontWeight="medium"
            gutterBottom
          >
            {dynamicProp.label}
          </SoftTypography>
          <SoftTypography variant="caption" color="text">
            {dynamicProp.accessor(bookingData)}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

function TransactionInfo({ transaction, booking }) {
  const [openAircraftInfo, setOpenAircraftInfo] = useState(false);
  const [openBookingInfo, setOpenBookingInfo] = useState(false);
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(false);
  const [openReceipt, setOpenReceipt] = useState(false);

  console.log("transaction", transaction, booking);

  const handleClose = () => {
    setOpenReceipt(false);
  };

  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;

  const documentHeader = (state) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
      return null;
    }

    return <div>{booking?.receipt?.fileName || ""}</div>;
  };

  const handleAircraftInfoOpen = () => {
    setOpenAircraftInfo(true);
  };

  const handleAircraftInfoClose = () => {
    setOpenAircraftInfo(false);
  };

  const handleBookingInfoOpen = () => {
    setOpenBookingInfo(true);
  };

  const handleBookingInfoClose = () => {
    setOpenBookingInfo(false);
  };

  const handleUserInfoOpen = (userId) => {
    setSelectedUserId(userId);
    setOpenUserInfo(true);
  };

  const handleUserInfoClose = () => {
    setSelectedUserId(null);
    setOpenUserInfo(false);
  };

  const renderProductDetails = () =>
    transaction.products.map((product) => (
      <Grid key={product.id} item xs={12} md={6}>
        <SoftBox display="flex" alignItems="center">
          <SoftBox mr={2}>
            <SoftAvatar
              variant="rounded"
              size="xxl"
              src={orderImage}
              alt="Gold Glasses"
            />
          </SoftBox>
          <SoftBox lineHeight={1}>
            <SoftTypography variant="h6" fontWeight="medium">
              {product.name}
            </SoftTypography>
            <SoftBox mb={2}>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                Order was delivered 2 days ago.
              </SoftTypography>
            </SoftBox>
            <SoftBadge
              variant="gradient"
              color="success"
              size="xs"
              badgeContent="delivered"
              container
            />
          </SoftBox>
        </SoftBox>
      </Grid>
    ));

  const renderBookingDetails = () => (
    <Grid item xs={12}>
      <SoftBox>
        <Grid
          container
          alignItems="center"
          textAlign="center"
          spacing={1}
          display="flex"
          justifyContent="center"
        >
          <Grid item xs={12} sm={8}>
            {booking?.start && booking?.end && (
              <ReservationDate
                start={
                  booking.start instanceof Date
                    ? booking.start
                    : booking.start.toDate()
                }
                end={
                  booking.end instanceof Date
                    ? booking.end
                    : booking.end.toDate()
                }
              />
            )}
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SoftBox sx={{ height: "100%" }} borderRadius="lg">
              <Grid container spacing={0}>
                {(booking?.extendedProps?.type?.value === "reservation" ||
                  booking?.extendedProps?.type?.value === "pilotRefresher" ||
                  booking?.extendedProps?.type?.value === "solo" ||
                  booking?.extendedProps?.type?.value === "rental" ||
                  booking?.extendedProps?.type?.value === "rideShare" ||
                  booking?.extendedProps?.type?.value === "ferryFlight" ||
                  booking?.extendedProps?.type?.value === "checkout" ||
                  booking?.extendedProps?.type?.value === "checkride" ||
                  booking?.extendedProps?.type?.value === "introFlight") && (
                  <BookingData
                    booking={booking}
                    mb={1}
                    userSelected={
                      booking?.extendedProps?.type?.value === "introFlight"
                        ? null
                        : handleUserInfoOpen
                    }
                  />
                )}
                {BookingInfoDynamicProps.map((dynamicProp) => (
                  <Grid key={dynamicProp.accessor} item xs={12} sm={4} mt={2}>
                    <DynamicPropCard
                      dynamicProp={dynamicProp}
                      bookingData={booking}
                      onClick={
                        dynamicProp.label === "Reservation #" &&
                        booking?.extendedProps?.type?.value !== "introFlight"
                          ? handleBookingInfoOpen
                          : dynamicProp.label === "Aircraft" &&
                            booking?.extendedProps?.type?.value !==
                              "introFlight"
                          ? handleAircraftInfoOpen
                          : null
                      }
                    />
                  </Grid>
                ))}
                {booking?.receipt?.url && (
                  <Grid
                    container
                    alignItems="center"
                    textAlign="center"
                    spacing={1}
                    display="flex"
                    justifyContent="center"
                    mt={3}
                  >
                    <Grid item xs={12}>
                      <SoftButton
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={() => setOpenReceipt(true)}
                      >
                        View Receipt
                      </SoftButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openReceipt}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <ModalContainer handleClose={handleClose}>
            <DocViewer
              documents={[
                {
                  uri: booking?.receipt?.url,
                  fileType: booking?.receipt?.fileType,
                },
              ]}
              pluginRenderers={DocViewerRenderers}
              config={{
                header: {
                  overrideComponent: documentHeader,
                  disableFileName: false,
                },
              }}
            />
          </ModalContainer>
        </SoftBox>
      </Modal>
    </Grid>
  );

  const renderMembershipDetails = () => (
    <Grid item xs={12}>
      <SoftBox>
        <Grid
          container
          alignItems="center"
          textAlign="center"
          spacing={1}
          display="flex"
          justifyContent="center"
        >
          <Grid item xs={12} sm={8}>
            <ReservationDate start={new Date()} end={new Date()} />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SoftBox sx={{ height: "100%" }} borderRadius="lg">
              <SoftBox />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Grid>
  );

  const renderPaymentRequestDetails = () => (
    <Grid item xs={12}>
      <SoftBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SoftBox sx={{ height: "100%" }} borderRadius="lg">
              <Table
                sx={{
                  minWidth: "32rem",
                }}
              >
                <SoftBox component="thead">
                  <TableRow>
                    <SoftBox
                      component="th"
                      width={{
                        xs: "45%",
                        md: "50%",
                      }}
                      py={1.5}
                      px={1}
                      textAlign="left"
                      borderBottom={borderBottom}
                    >
                      <SoftTypography
                        variant="h6"
                        color="text"
                        fontWeight="medium"
                      >
                        Item
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      component="th"
                      py={1.5}
                      pl={3}
                      pr={1}
                      textAlign="left"
                      borderBottom={borderBottom}
                    >
                      <SoftTypography
                        variant="h6"
                        color="text"
                        fontWeight="medium"
                      >
                        Qty
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      component="th"
                      py={1.5}
                      pl={3}
                      pr={1}
                      textAlign="left"
                      borderBottom={borderBottom}
                    >
                      <SoftTypography
                        variant="h6"
                        color="text"
                        fontWeight="medium"
                      >
                        Rate
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      component="th"
                      py={1.5}
                      pl={3}
                      pr={1}
                      textAlign="left"
                      borderBottom={borderBottom}
                    >
                      <SoftTypography
                        variant="h6"
                        color="text"
                        fontWeight="medium"
                      >
                        Amount
                      </SoftTypography>
                    </SoftBox>
                  </TableRow>
                </SoftBox>
                <TableBody>
                  {transaction?.lineItems?.map((item) => (
                    <TableRow key={item.id}>
                      <SoftBox
                        component="td"
                        textAlign="left"
                        p={1}
                        borderBottom={borderBottom}
                      >
                        <SoftTypography variant="body2" color="text">
                          {item?.item}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox
                        component="td"
                        textAlign="left"
                        py={1}
                        pr={1}
                        pl={3}
                        borderBottom={borderBottom}
                      >
                        <SoftTypography variant="body2" color="text">
                          {item?.qty}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox
                        component="td"
                        textAlign="left"
                        py={1}
                        pr={1}
                        pl={3}
                        borderBottom={borderBottom}
                      >
                        <SoftTypography variant="body2" color="text">
                          {formatCurrency(
                            item.type === "credit" ||
                              item.type === "accountBalance"
                              ? item.price * -1
                              : item.price
                          )}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox
                        component="td"
                        textAlign="left"
                        py={1}
                        pr={1}
                        pl={3}
                        borderBottom={borderBottom}
                      >
                        <SoftTypography variant="body2" color="text">
                          {formatCurrency(
                            item.type === "credit" ||
                              item.type === "accountBalance"
                              ? item.total * -1
                              : item.total
                          )}
                        </SoftTypography>
                      </SoftBox>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Grid>
  );

  const renderFundingDetails = () => (
    <Grid item xs={12}>
      {/* // TODO: Add funding details */}
    </Grid>
  );

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        {transaction.type === "order" && renderProductDetails()}
        {(transaction.type === "booking" ||
          transaction.type === "introFlight") &&
          renderBookingDetails()}
        {transaction.type === "membership" && renderMembershipDetails()}
        {transaction.type === "accountFunding" && renderFundingDetails()}
        {transaction.type === "deposit" && renderFundingDetails()}
        {transaction.type === "paymentRequest" && renderPaymentRequestDetails()}
      </Grid>
      {transaction?.booking?.id && (
        <Modal
          open={openBookingInfo}
          onClose={handleBookingInfoClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backdropFilter: "blur(2px)",
          }}
        >
          <SoftBox>
            <BookingInfoModal
              bookingId={transaction?.booking?.id}
              handleClose={handleBookingInfoClose}
            />
          </SoftBox>
        </Modal>
      )}
      {transaction?.aircraft?.id && (
        <Modal
          open={openAircraftInfo}
          onClose={handleAircraftInfoClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backdropFilter: "blur(2px)",
          }}
        >
          <SoftBox>
            <AircraftInfoModal
              handleClose={handleAircraftInfoClose}
              aircraft={transaction?.aircraft}
            />
          </SoftBox>
        </Modal>
      )}
      <Modal
        open={openUserInfo}
        onClose={handleUserInfoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <UserInfoModal
            handleClose={handleUserInfoClose}
            userId={selectedUserId}
          />
        </SoftBox>
      </Modal>
    </>
  );
}

DynamicPropCard.propTypes = {
  dynamicProp: PropTypes.object.isRequired,
  bookingData: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

TransactionInfo.propTypes = {
  transaction: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
};

export default TransactionInfo;
