import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SoftBox from "src/components/SoftBox";
import Checkbox from "@mui/material/Checkbox";
import { Select, Option } from "src/components/SoftMultiSelect";
import SoftButton from "src/components/SoftButton";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Document from "src/components/Icons/Document";
import Settings from "src/components/Icons/Settings";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import DocumentsList from "src/components/DocumentsList";
import AppBar from "@mui/material/AppBar";
import { AccessTimeFilled, ExpandMore } from "@mui/icons-material";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { useClubs } from "src/features/club/ClubProvider";

import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import SoftTypography from "src/components/SoftTypography";
import SoftBadge from "src/components/SoftBadge";
import TabPanel from "src/components/TabPanel";
import DataTable from "src/components/Tables/DataTable";
import {
  getUserCheckoutsCollection,
  getUserDocumentsCollection,
  getUserPermissionsCollection,
  getMembershipsCollection,
  getClubPrefsCollection,
  getUsersCollection,
} from "src/features/user/collections";
import {
  getClubMembershipsCollection,
  getClubDocumentTypesCollection,
} from "src/features/club/collections";
import Modal from "@mui/material/Modal";
import { UploadDocumentModal } from "src/modals/UploadDocument";
import CheckOutModal from "./CheckOutModal";
import { AddCheckoutModal } from "./AddCheckoutModal";
// import { AddEndorsementModal } from 'src/modals/AddEndorsement';
import { EditMembershipModal } from "src/modals/EditMembershipModal";
import InformationBite from "src/components/InformationBite";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { format, isPast } from "date-fns";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
} from "@mui/material";
import colors from "src/assets/theme/base/colors";
const { light } = colors;

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  query,
  where,
} from "firebase/firestore";
import { UserAvatar } from "src/components/UserAvatar";
import { useUserRolesDocuments } from "src/hooks/useUserRolesDocuments";
import { usePromiseLoading } from "src/hooks/usePromiseLoading";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";
import PaymentMethods from "src/components/PaymentMethods";
import Swal from "sweetalert2";
import toastr from "toastr";
import Notes from "src/components/Notes";
import ModalWrapper from "src/modals/ModalWrapper/ModalWrapper";
import WrappedAddBadgeModal from "src/modals/AddBadgeModal/WrappedAddBadgeModal";
import { openModal$ } from "src/modals/modalConfiguration";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.5,
  py: 0,
  top: "-1px",

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

const iconAnim = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const StyledAirplanemodeActive = styled(PersonSearchIcon)`
  animation: ${iconAnim} 4s infinite;
`;

const aircraftCheckoutColumns = [
  {
    Header: "Tail Number",
    accessor: "tailNumber",
    width: "15%",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Instructor",
    accessor: "instructor.displayName",
    width: "20%",
  },
  {
    Header: "Expires",
    accessor: "expires",
    width: "10%",
    Cell: ({ value }) => (
      <DefaultCell
        value={`${
          value?.toDate() ? format(value.toDate(), "MM/dd/yyyy") : "Never"
        }`}
      />
    ),
  },
  {
    Header: "Status",
    width: "10%",
    Cell: ({
      row: {
        original: { expires },
      },
    }) =>
      expires && isPast(expires.toDate()) ? (
        <SoftBadge
          variant="contained"
          color="error"
          size="xs"
          badgeContent="Expired"
          container
          sx={{
            marginRight: 1,
          }}
        />
      ) : (
        <SoftBadge
          variant="contained"
          color="success"
          size="xs"
          badgeContent="Current"
          container
          sx={{
            marginRight: 1,
          }}
        />
      ),
  },
  {
    Header: "Date Given",
    width: "10%",
    Cell: ({ row: { original } }) => {
      const { date } = original;
      return date.toDate().toDateString();
    },
  },
];

const standardDocumentTypes = [
  { label: "Photo ID", value: "photoId" },
  { label: "Pilot Certificate (FAA)", value: "certificate" },
  { label: "Medical Certificate / BasicMed (FAA)", value: "medical" },
  {
    label: "TSA Citizenship Verification",
    value: "tsaCitizenshipVerification",
  },
];

// const endorsementsColumns = [
//   {
//     Header: 'Date Given',
//     width: '20%',
//     Cell: ({ row: { original } }) => {
//       const { givenAt } = original;
//       return givenAt.toDate().toDateString();
//     },
//   },
//   {
//     Header: 'Endorsement',
//     accessor: 'title',
//   },
//   {
//     Header: 'Given By',
//     accessor: 'givenBy',
//     width: '30%',
//   },
// ];

function MemberAvatarInfoView({ member, membership, setOpenEditMembership }) {
  return (
    <Grid item xs={12}>
      <SoftBox padding={1} borderRadius="md">
        <SoftBox
          display="grid"
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
        >
          <UserAvatar user={member} size="xxl" />
          <SoftTypography mt={2} sx={{ textAlign: "center" }} variant="h4">
            {member.displayName}
          </SoftTypography>
          {membership && (
            <SoftTypography variant="caption">
              {membership.membershipPlan.label}{" "}
              {membership.nextMembershipPlan &&
                `-> ${membership.nextMembershipPlan.label}`}
              <IconButton
                aria-label="delete"
                sx={iconButtonStyles}
                onClick={() => setOpenEditMembership(true)}
              >
                <Icon>edit</Icon>
              </IconButton>
            </SoftTypography>
          )}
        </SoftBox>
      </SoftBox>
    </Grid>
  );
}

function MemberInfo({ member }) {
  const { selectedClubId, selectedLocationId, clubUserRoles } = useClubs();
  const [tabValue, setTabValue] = useState(0);
  const [instructionDiscount, setInstructionDiscount] = useState(0);
  const [flightDiscount, setFlightDiscount] = useState(0);
  const [checkouts, setCheckouts] = useState([]);
  const [membership, setMembership] = useState();
  const [clubPrefs, setClubPrefs] = useState();
  const [userRoles, setUserRoles] = useState([]);
  const { getUserRolesDocuments } = useUserRolesDocuments();
  const { hasAccess } = usePermissions();
  const {
    caller: fnGetUserRolesDocuments,
    data: userRolesData,
    isLoading: userRolesDataIsLoading,
  } = usePromiseLoading({
    fn: getUserRolesDocuments,
  });

  const { addData } = entityCrudUtils();

  useEffect(() => {
    if (member?.id) {
      fnGetUserRolesDocuments(member.id);
    }
  }, [member?.id]);

  useEffect(() => {
    if (userRolesDataIsLoading) return;
    setUserRoles(userRolesData?.userRoles || []);
  }, [userRolesDataIsLoading, userRolesData]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const [checkoutsSnapshot, isCheckoutsDataLoading] = useCollection(
    member && getUserCheckoutsCollection(member?.id)
  );
  const [membershipSnapshot, loadingMembership] = useDocument(
    doc(getMembershipsCollection(member?.id), selectedClubId)
  );

  const [clubPrefsSnapshot, loadingClubPrefs] = useDocument(
    doc(getClubPrefsCollection(member?.id), selectedClubId)
  );

  const clubDocumentTypesRef = getClubDocumentTypesCollection(selectedClubId);
  const { data: clubDocumentTypes, isDataLoaded: clubDocumentTypesLoaded } =
    useRealtimeCollectionData(
      query(clubDocumentTypesRef, where("deleted", "==", false))
    );

  const [allDocumentTypes, setAllDocumentTypes] = useState();

  useEffect(() => {
    const types = [...standardDocumentTypes];
    if (clubDocumentTypesLoaded) {
      clubDocumentTypes.forEach((element) => {
        types.push({
          label: element.name,
          value: element.id,
        });
      });
    }
    setAllDocumentTypes(types);
  }, [clubDocumentTypes, clubDocumentTypesLoaded]);

  useEffect(() => {
    if (!clubPrefsSnapshot) {
      setClubPrefs({ flightDiscount: 0, instructionDiscount: 0 });
      return;
    }
    setClubPrefs({ ...clubPrefsSnapshot.data(), id: clubPrefsSnapshot.id });
  }, [clubPrefsSnapshot, loadingClubPrefs]);

  useEffect(() => {
    setFlightDiscount(clubPrefs?.flightDiscount ?? 0);
    setInstructionDiscount(clubPrefs?.instructionDiscount ?? 0);
  }, [clubPrefs]);

  const [selectedCheckout, setSelectedCheckout] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAddCheckout, setOpenAddCheckout] = useState(false);
  const handleOpenAddCheckout = () => setOpenAddCheckout(true);
  const handleCloseAddCheckout = () => setOpenAddCheckout(false);

  const [openAddBadgeModal, setOpenAddBadgeModal] = useState(false);
  const handleOpenAddBadgeModal = () => {
    console.log("Opening Add Badge Modal", member);
    setOpenAddBadgeModal(true);
  };
  const handleCloseAddBadgeModal = (badgeNo) => {
    if (badgeNo) {
      member.rfid = badgeNo;
    }
    setOpenAddBadgeModal(false);
  };

  // const [openAddEndorsement, setOpenAddEndorsement] = useState(false);
  // const handleOpenAddEndorsement = () => setOpenAddEndorsement(true);
  // const handleCloseAddEndorsement = () => setOpenAddEndorsement(false);

  const [openEditMembership, setOpenEditMembership] = useState(false);
  const handleCloseEditMembership = () => {
    setOpenEditMembership(false);
  };

  const getMembershipDetails = async (membershipData) => {
    if (!membershipData) return;
    const membershipPlan = await getDoc(
      doc(
        getClubMembershipsCollection(selectedClubId),
        membershipData.membershipPlan
      )
    );

    let nextMembershipPlan;
    if (membershipData.nextMembershipPlan) {
      const nextPlanDoc = await getDoc(
        doc(
          getClubMembershipsCollection(selectedClubId),
          membershipData.nextMembershipPlan
        )
      );
      nextMembershipPlan = { ...nextPlanDoc.data(), id: nextPlanDoc.id };
    }

    setMembership({
      ...membershipData,
      membershipPlan: { ...membershipPlan.data(), id: membershipPlan.id },
      nextMembershipPlan,
    });
  };

  useEffect(() => {
    if (!membershipSnapshot || loadingMembership) return;
    getMembershipDetails(membershipSnapshot.data());
  }, [membershipSnapshot]);

  useEffect(() => {
    if (!checkoutsSnapshot || isCheckoutsDataLoading) return;
    const newCheckouts = [];
    checkoutsSnapshot.docs.map((a) => {
      const data = a.data();
      newCheckouts.push({
        ...data,
        id: a.id,
      });
    });
    setCheckouts(newCheckouts);
  }, [checkoutsSnapshot?.docs.length]);

  // const handleCloseAddDocument = async (documentsAdded) => {
  //   if (documentsAdded && documentsAdded.length > 0) {
  //     await Promise.all(
  //       documentsAdded.map(async (document) => {
  //         await addData(getUserDocumentsCollection(member.id), document);
  //       })
  //     );
  //   }
  //   setOpenAddDocument(false);
  // };

  const handleCheckoutSelected = (row) => {
    handleOpen(true);
    setSelectedCheckout(row);
  };

  // const handleEndorsementSelected = () => {
  //   handleOpen(true);
  // };

  const updateUser = async (userData) => {
    await setDoc(doc(getUsersCollection(), member.id), userData, {
      merge: true,
    });
  };

  const updateClubPrefs = async (clubPrefsData) => {
    await setDoc(
      doc(getClubPrefsCollection(member.id), selectedClubId),
      clubPrefsData,
      {
        merge: true,
      }
    );
  };

  const onChangePilotHeight = (event) => {
    let heightEntered = Number(event.target.value);
    if (!heightEntered || Number.isNaN(heightEntered)) heightEntered = 0;
    member.height = heightEntered;
    updateUser({ height: heightEntered });
  };

  const onChangePilotWeight = (event) => {
    let weightEntered = Number(event.target.value);
    if (!weightEntered || Number.isNaN(weightEntered)) weightEntered = 0;
    member.weight = weightEntered;
    updateUser({ weight: weightEntered });
  };

  const onChangeFlightDiscount = (event) => {
    let flightDiscountEntered = Number(event.target.value);
    if (!flightDiscountEntered || Number.isNaN(flightDiscountEntered))
      flightDiscountEntered = 0;

    updateClubPrefs({ ...clubPrefs, flightDiscount: flightDiscountEntered });
  };

  const onChangeInstructionDiscount = (event) => {
    let instructionDiscountEntered = Number(event.target.value);
    if (!instructionDiscountEntered || Number.isNaN(instructionDiscountEntered))
      instructionDiscountEntered = 0;

    updateClubPrefs({
      ...clubPrefs,
      instructionDiscount: instructionDiscountEntered,
    });
  };

  const deleteDocument = async (document) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          await updateDoc(
            doc(getUserDocumentsCollection(member.id), document.id),
            { deleted: true }
          );
          toastr.success("Document deleted successfully");
        }
      });
  };

  const onAddUserRole = async (userRoles) => {
    await setDoc(
      doc(getUserPermissionsCollection(member.id), selectedLocationId),
      {
        userRoles,
      },
      { merge: true }
    );
    // await fnGetUserRolesDocuments(member.id);
  };

  if (!member)
    return (
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flexGrow="1"
        sx={{ height: "100%" }}
      >
        <StyledAirplanemodeActive
          color="primary"
          sx={{ width: 130, height: 130 }}
        />
        <SoftTypography>Select a member to see their details</SoftTypography>
      </SoftBox>
    );
  return (
    <SoftBox sx={{ flexGrow: 1 }} variant="contained" padding={1}>
      <Grid container columns={{ xs: 12 }}>
        <Grid item xs={12} borderRadius="sm">
          <SoftBox display="flex" justifyContent="center">
            <MemberAvatarInfoView
              member={member}
              membership={membership}
              setOpenEditMembership={setOpenEditMembership}
            />
          </SoftBox>
        </Grid>
        <Grid item xs={12} padding={1}>
          <AppBar position="static">
            <Tabs
              orientation="horizontal"
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{
                backgroundColor: "transparent",
              }}
            >
              <Tab label="Information" icon={<AccessTimeFilled />} />
              <Tab
                label="Pilot Data"
                icon={<Document />}
                hidden={!hasAccess([systemPermissions.EDIT_MEMBERS])}
              />
              <Tab
                label="Contacts"
                icon={<Settings />}
                hidden={!hasAccess([systemPermissions.EDIT_MEMBERS])}
              />
              <Tab
                label="Settings"
                icon={<Settings />}
                hidden={!hasAccess([systemPermissions.EDIT_MEMBERS])}
              />
            </Tabs>
          </AppBar>

          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={{ xs: 1 }} columns={{ xs: 12 }}>
              <Grid item xs={4}>
                <InformationBite
                  label="First Name"
                  value={member.firstName || ""}
                />
              </Grid>
              <Grid item xs={4}>
                <InformationBite
                  label="Last Name"
                  value={member.lastName || ""}
                />
              </Grid>{" "}
              <Grid item xs={4}>
                <InformationBite
                  label="Date of Birth"
                  value={
                    member.birthYear && member.birthMonth && member.birthDay
                      ? `${member.birthMonth}/${member.birthDate}/${member.birthYear}`
                      : "Not Given"
                  }
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={{ xs: 1 }} columns={{ xs: 12 }}>
              <Grid item xs={7}>
                <InformationBite
                  label="Phone"
                  value={member.phoneNumber || ""}
                />
              </Grid>
              <Grid item xs={5}>
                <InformationBite
                  label="Roles"
                  capitalize
                  value={
                    userRolesData?.parsedUserRoles
                      ?.map((r) => r.label)
                      .join(", ") || ""
                  }
                />
              </Grid>
              <Grid item xs={7}>
                <InformationBite label="Email" value={member.email || ""} />
              </Grid>
              <Grid item xs={5}>
                <InformationBite
                  label="Last Login"
                  value={
                    member?.status?.last_changed
                      ? format(
                          member.status.last_changed.toDate(),
                          "MMM dd yyyy hh:mm a"
                        )
                      : "Never"
                  }
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <SoftTypography variant="caption">
              <WithPermissions permissions={systemPermissions.EDIT_MEMBERS}>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Height
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <SoftBox
                      display="flex"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <SoftBox width={90}>
                        <SoftCurrencyInput
                          style={{
                            ".MuiInputAdornment-root": { right: "-54px" },
                          }}
                          currencySymbol="in"
                          placeholder="0"
                          decimalPlaces={0}
                          decimalPlacesShownOnBlur={0}
                          decimalPlacesShownOnFocus={0}
                          outputFormat="number"
                          adornmentOnRight
                          value={member.height || 0}
                          onBlur={onChangePilotHeight}
                        />
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Weight
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <SoftBox
                      display="flex"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <SoftBox width={90}>
                        <SoftCurrencyInput
                          currencySymbol="lbs"
                          placeholder="0"
                          decimalPlaces={0}
                          decimalPlacesShownOnBlur={0}
                          decimalPlacesShownOnFocus={0}
                          outputFormat="number"
                          adornmentOnRight
                          value={member.weight || 0}
                          onBlur={onChangePilotWeight}
                        />
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </WithPermissions>
            </SoftTypography>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <SoftTypography variant="caption">
              <i>No emergency contacts to display</i>
            </SoftTypography>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <WithPermissions permissions={systemPermissions.EDIT_MEMBERS}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                lineHeight={1}
              >
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Flight Cost Discount
                </SoftTypography>
                <SoftBox ml={2} mr={1}>
                  <SoftBox
                    display="flex"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <SoftBox width={90}>
                      <SoftCurrencyInput
                        style={{
                          ".MuiInputAdornment-root": { right: "-54px" },
                        }}
                        currencySymbol="%"
                        placeholder="0"
                        decimalPlaces={1}
                        decimalPlacesShownOnBlur={1}
                        decimalPlacesShownOnFocus={1}
                        outputFormat="number"
                        adornmentOnRight
                        value={flightDiscount}
                        onBlur={onChangeFlightDiscount}
                      />
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                lineHeight={1}
              >
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Instruction Cost Discount
                </SoftTypography>
                <SoftBox ml={2} mr={1}>
                  <SoftBox
                    display="flex"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <SoftBox width={90}>
                      <SoftCurrencyInput
                        currencySymbol="%"
                        placeholder="0"
                        decimalPlaces={1}
                        decimalPlacesShownOnBlur={1}
                        decimalPlacesShownOnFocus={1}
                        outputFormat="number"
                        adornmentOnRight
                        value={instructionDiscount}
                        onBlur={onChangeInstructionDiscount}
                      />
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>

              <WithPermissions
                permissions={systemPermissions.EDIT_MEMBER_ROLES}
              >
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <Stack spacing={2}>
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      User Roles
                    </SoftTypography>
                  </Stack>
                  <SoftBox ml={2} mr={1}>
                    <SoftBox
                      display="flex"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <SoftBox>
                        <Select
                          id="roleSelect"
                          multiple
                          value={userRoles}
                          onChange={(event, newValue) => {
                            setUserRoles(newValue);
                            onAddUserRole(newValue);
                          }}
                          renderValue={(selected) => {
                            if (selected.length > 0) {
                              return selected.length === clubUserRoles.length
                                ? "All"
                                : `${selected.length} selected`;
                            } else {
                              return "None Selected";
                            }
                          }}
                        >
                          {clubUserRoles?.map((option) => (
                            <Option key={option.id} value={option.id}>
                              <SoftBox sx={{ display: "flex" }}>
                                <Checkbox
                                  checked={userRoles?.some(
                                    (selected) => selected === option?.id
                                  )}
                                />
                                <SoftTypography variant="body2">
                                  {option.label}
                                </SoftTypography>
                              </SoftBox>
                            </Option>
                          ))}
                        </Select>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </WithPermissions>

              <WithPermissions
                permissions={systemPermissions.EDIT_MEMBER_BADGES}
              >
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <Stack direction="row" spacing={2}>
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Badge No:
                    </SoftTypography>
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      ml={2}
                    >
                      {member.rfid || "Not Assigned"}
                    </SoftTypography>
                  </Stack>
                  <SoftBox ml={2} mr={1}>
                    <SoftBox>
                      <SoftButton
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOpenAddBadgeModal()}
                      >
                        {member.rfid ? "Update" : "Add"} Badge
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </WithPermissions>
            </WithPermissions>
          </TabPanel>
        </Grid>
        <Grid item xs={12} mt={2} borderRadius="sm">
          <Notes selectedUserId={member.id} />
        </Grid>
        <WithPermissions
          permissions={systemPermissions.VIEW_AIRCRAFT_CHECKOUTS}
        >
          <Grid item xs={12} mt={1.5} borderRadius="sm">
            <Accordion
              elevation={0}
              sx={{ border: 1, borderColor: light.main }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                  background: "#E9ecef",
                }}
              >
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  bgColor="light"
                  width="100%"
                >
                  <SoftTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    Aircraft Checkouts
                  </SoftTypography>
                  <WithPermissions
                    permissions={systemPermissions.GIVE_AIRCRAFT_CHECKOUTS}
                  >
                    <SoftButton
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={handleOpenAddCheckout}
                    >
                      <Icon>add</Icon>&nbsp; add new
                    </SoftButton>
                  </WithPermissions>
                </SoftBox>
              </AccordionSummary>
              <AccordionDetails>
                <SoftBox
                  xs={12}
                  padding={1}
                  sx={{
                    maxWidth: "100%",
                    overflow: "hidden",
                  }}
                >
                  <DataTable
                    entriesPerPage={false}
                    maxWidth="100%"
                    table={{
                      columns: aircraftCheckoutColumns,
                      rows: checkouts,
                    }}
                    onRowSelect={handleCheckoutSelected}
                  />
                </SoftBox>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </WithPermissions>
        <WithPermissions permissions={systemPermissions.VIEW_MEMBER_DOCUMENTS}>
          <Grid item xs={12} mt={1.5} borderRadius="sm">
            {allDocumentTypes?.length > 0 && clubDocumentTypesLoaded && (
              <DocumentsList
                title="Documents"
                documentsCollection={getUserDocumentsCollection(member.id)}
                openUploadModal={() =>
                  openModal$.next({
                    modalName: "NEW_UPLOAD_DOCUMENT",
                    modalProps: {
                      storagePath: `users/${member.id}/documents`,
                      memberId: member.id,
                    },
                  })
                }
                clickHandler={(doc) =>
                  openModal$.next({
                    modalName: "NEW_UPLOAD_DOCUMENT",
                    modalProps: {
                      storagePath: `users/${member.id}/documents`,
                      memberId: member.id,
                      document: doc
                    },
                  })
                }
                documentTypes={allDocumentTypes}
                ownerId={member.id}
                deleteDocument={deleteDocument}
                hasDocumentsWithExpiration
              />
            )}
          </Grid>
        </WithPermissions>
        <Grid item xs={12} mt={1.5} borderRadius="sm">
          <PaymentMethods user={member} />
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <CheckOutModal selectedCheckout={selectedCheckout} />
        </SoftBox>
      </Modal>
      <Modal
        open={openAddCheckout}
        onClose={handleCloseAddCheckout}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddCheckoutModal
            handleClose={handleCloseAddCheckout}
            selectedMember={member}
          />
        </SoftBox>
      </Modal>
      {/* <Modal
        open={openAddDocument}
        onClose={handleCloseAddDocument}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <UploadDocumentModal
            handleClose={handleCloseAddDocument}
            documentTypes={standardDocumentTypes}
            storagePath={`users/${member.id}/documents`}
          />
        </SoftBox>
      </Modal> */}
      {/* <Modal
        open={openAddEndorsement}
        onClose={handleCloseAddEndorsement}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SoftBox>
          <AddEndorsementModal handleClose={handleCloseAddEndorsement} />
        </SoftBox>
      </Modal> */}
      <Modal
        open={openEditMembership}
        onClose={handleCloseEditMembership}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <EditMembershipModal
            handleClose={handleCloseEditMembership}
            selectedMembership={membership}
            memberId={member.id}
          />
        </SoftBox>
      </Modal>
      <ModalWrapper
        isOpen={openAddBadgeModal}
        onClose={() => handleCloseAddBadgeModal()}
      >
        <WrappedAddBadgeModal
          isOpen={openAddBadgeModal}
          onClose={handleCloseAddBadgeModal}
          userId={member.uid}
        />
      </ModalWrapper>
    </SoftBox>
  );
}

MemberInfo.propTypes = {
  member: PropTypes.object,
};

export default MemberInfo;
