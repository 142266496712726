import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { useClubs } from "src/features/club/ClubProvider";
import { formatCurrency } from "src/features/utils";
import {
  differenceInHours,
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  endOfDay,
  startOfDay,
} from "date-fns";
import { query, orderBy, where, getDocs, limit } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import { getClubTransactionsCollection } from "src/features/transaction/collection";

const columns = [
  {
    Header: "First Name",
    accessor: "firstName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Instructor Type",
    accessor: "instructorType",
    Cell: ({ value }) => (
      <DefaultCell textTransform="uppercase" value={value || "---"} />
    ),
  },
  {
    Header: "Billable Rate",
    accessor: "billableRate",
    Cell: ({ value }) => <DefaultCell value={formatCurrency(value) || "---"} />,
  },
  {
    Header: "Billable Hours",
    accessor: "totalBillableHours",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Total Billed",
    accessor: "totalBilled",
    Cell: ({ value }) => <DefaultCell value={formatCurrency(value) || "---"} />,
  },
  {
    Header: "Flight Hours",
    accessor: "totalFlight",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Sim Hours",
    accessor: "totalSim",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Ground Hours",
    accessor: "totalGround",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Time Off",
    accessor: "totalTimeOff",
    Cell: ({ value }) => <DefaultCell value={value.toString() || "---"} />,
  },
  {
    Header: "Last Flight",
    accessor: "lastFlight",
    Cell: ({ value }) => (
      <DefaultCell
        value={value ? format(value.toDate(), "eee MMM do yyyy") : "---"}
      />
    ),
  },
];

function InstructorSummaryReport() {
  const {
    selectedClubId,
    selectedClub,
    selectedLocationId,
    selectedLocation,
    clubUsersPermissions,
    instructors,
    instructorPreferences,
  } = useClubs();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const addInstructorData = async (instructors) => {
    const rowData = await Promise.all(
      instructors.map(async (instructor) => {
        const instructorPrefs = instructorPreferences.get(instructor.id);

        const userPermissions = clubUsersPermissions.get(instructor.id);
        const latestBookings = await getDocs(
          query(
            getClubBookingsCollection(selectedClubId, selectedLocationId),
            where("extendedProps.instructor.value", "==", instructor.id),
            where("cancelled", "==", false),
            orderBy("completedAt", "desc"),
            limit(1)
          )
        );

        const instructorTimeOff = await getDocs(
          query(
            getClubBookingsCollection(selectedClubId, selectedLocationId),
            where("extendedProps.instructor.value", "==", instructor.id),
            where("extendedProps.type.value", "==", "unavailable"),
            where("end", ">=", dateRange[0]),
            where("cancelled", "==", false)
          )
        );

        let totalTimeOff = 0;
        instructorTimeOff?.docs?.forEach((booking) => {
          if (booking?.data()?.extendedProps?.type?.value === "unavailable") {
            const { start, end } = booking.data();
            if (
              start.toDate() <= dateRange[1] &&
              end.toDate() >= dateRange[0]
            ) {
              totalTimeOff += differenceInHours(
                end.toDate() > dateRange[1] ? dateRange[1] : end.toDate(),
                start.toDate() < dateRange[0] ? dateRange[0] : start.toDate()
              );
            }
          }
        });

        const instructorPayments = await getDocs(
          query(
            getClubTransactionsCollection(selectedClubId),
            where("location.id", "==", selectedLocationId),
            where("instructor.uid", "==", instructor.id),
            where("createdAt", ">=", dateRange[0]),
            where("type", "==", "booking")
          )
        );

        let totalBilled = 0;
        let totalBillableHours = 0;
        let totalFlight = 0;
        let totalSim = 0;
        let totalGround = 0;
        instructorPayments.docs.forEach((transaction) => {
          const {
            instructorCost,
            instructorCostGround,
            bookingData,
            aircraft,
          } = transaction.data();
          if (transaction.data()?.createdAt?.toDate() <= dateRange[1]) {
            if (instructorCost > 0) totalBilled += instructorCost;
            if (instructorCostGround > 0) totalBilled += instructorCostGround;
            if (bookingData?.instructionHoursGround > 0) {
              totalBillableHours += bookingData.instructionHours;
              totalBillableHours += bookingData.instructionHoursGround;
              if (bookingData.instructionHoursGround) {
                totalGround += bookingData.instructionHoursGround;
              }
            }
            if (bookingData?.totalHobbsHours > 0 && !aircraft?.simulator)
              totalFlight += bookingData.totalHobbsHours;
            if (bookingData?.totalHobbsHours > 0 && aircraft?.simulator)
              totalSim += bookingData.totalHobbsHours;
          }
        });

        const fullInstructorData = {
          ...instructor,
          userType: userPermissions?.userType,
          instructorType: instructorPrefs?.instructorType,
          billableRate: instructorPrefs?.hourlyRate || 0,
          totalBilled: Math.round(totalBilled * 100) / 100,
          totalBillableHours: Math.round(totalBillableHours * 100) / 100,
          totalFlight: Math.round(totalFlight * 100) / 100,
          totalSim: Math.round(totalSim * 100) / 100,
          totalGround: Math.round(totalGround * 100) / 100,
          lastFlight: latestBookings?.docs[0]?.data()?.completedAt,
          totalTimeOff,
        };

        return fullInstructorData;
      })
    );

    const csvDataFromRows = rowData.map((row) => ({
      "First Name": row.firstName,
      "Last Name": row.lastName,
      "Instructor Type": row.instructorType,
      "Billable Rate": row.billableRate,
      "Billable Hours": row.totalBillableHours,
      "Total Billed": row.totalBilled,
      "Flight Hours": row.totalFlight,
      "Sim Hours": row.totalSim,
      "Ground Hours": row.totalGround,
      "Time Off": row.totalTimeOff,
      "Last Flight": row.lastFlight
        ? format(row.lastFlight.toDate(), "eee MMM do yyyy")
        : "---",
    }));

    setCsvData(csvDataFromRows);

    setRows(rowData);
  };

  useEffect(() => {
    if (!instructors || !instructorPreferences) return;
    addInstructorData(instructors);
  }, [instructors, instructorPreferences, dateRange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <CSVLink
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-instructor_summary-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default InstructorSummaryReport;
